<template>
  <div class="bidding-detail">
    <div class="breadcrumb" v-show="!isFullscreen">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> 行业信息库 </el-breadcrumb-item>
        <el-breadcrumb-item to="/project/land-transaction">土地交易信息库</el-breadcrumb-item>
        <el-breadcrumb-item> 土地信息详情 </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="file-box">
      <div class="file-name-box">
        <div class="file-name-left">
          <!-- <div class="file-img"></div> -->
          <div>
            <el-tooltip effect="light" :content="detailData.projectName" placement="bottom-start">
              <div class="file-name text-ellipsis">
                {{ detailData.projectName }}
              </div>
            </el-tooltip>
          </div>
          <div class="download-box" @click="click">
            <span :class="isFullscreen
              ? 'iconfont icon-quxiaoquanping'
              : 'iconfont icon-quanping'
              "></span>
            <span>{{ isFullscreen ? "取消全屏" : "全屏预览" }}</span>
          </div>
        </div>
        <div class="file-name-right" v-if="detailData.projectLink" @click="toOriginal(detailData.projectLink)">
          查看原文
        </div>
      </div>
      <div :class="isFullscreen
        ? 'full-file-content ql-editor'
        : 'file-content ql-editor'
        ">
        <el-descriptions :column="2" border v-if="info" title="供地结果信息">
          <el-descriptions-item label="行政区">{{ info.xzqFullName || '--' }}</el-descriptions-item>
          <el-descriptions-item label="电子监管号">{{ info.dzBaBh || '--' }}</el-descriptions-item>
          <el-descriptions-item label="项目名称">{{ info.xmMc || '--' }}</el-descriptions-item>
          <el-descriptions-item label="项目位置">{{ info.tdZl || '--' }}</el-descriptions-item>
          <el-descriptions-item label="土地面积(㎡)">{{ info.gyMj * 10000 }}</el-descriptions-item>
          <el-descriptions-item label="土地用途">{{ info.tdYt || '--' }}</el-descriptions-item>
          <el-descriptions-item label="供地方式">{{ info.gyFs || '--' }}</el-descriptions-item>
          <el-descriptions-item label="土地使用年限">{{ info.tdsynx || '--' }}</el-descriptions-item>
          <el-descriptions-item label="行业分类">{{ info.hyFl || '--' }}</el-descriptions-item>
          <el-descriptions-item label="土地级别">{{ info.tdJb || '--' }}</el-descriptions-item>
          <el-descriptions-item label="成交价格(万元)">{{ info.je || '--' }}</el-descriptions-item>
          <el-descriptions-item label="土地使用权人">{{ info.srr }}</el-descriptions-item>
          <el-descriptions-item label="分期支付约定" :span="2">
            <el-row>
              <el-col :span="6">支付期号：{{ '--' }}</el-col>
              <el-col :span="6">约定支付日期：{{ '--' }}</el-col>
              <el-col :span="6">约定支付金额：{{ '--' }}</el-col>
              <el-col :span="6">备注：{{ '--' }}</el-col>
            </el-row>
          </el-descriptions-item>
          <el-descriptions-item label="约定容积率">
            <el-row>
              <el-col :span="12">下限：{{ '--' }}</el-col>
              <el-col :span="12">上限：{{ '--' }}</el-col>
            </el-row>
          </el-descriptions-item>
          <el-descriptions-item label="约定交地时间">{{ replaceTimeT(info.jdSj) || '--' }}</el-descriptions-item>
          <el-descriptions-item label="约定开工时间">{{ replaceTimeT(info.dgSj) || '--' }}</el-descriptions-item>
          <el-descriptions-item label="约定竣工时间">{{ replaceTimeT(info.jgSj) || '--' }}</el-descriptions-item>
          <el-descriptions-item label="实际开工时间">{{ '--' }}</el-descriptions-item>
          <el-descriptions-item label="实际竣工时间">{{ '--' }}</el-descriptions-item>
          <el-descriptions-item label="批准单位">{{ info.pzJg || '--' }}</el-descriptions-item>
          <el-descriptions-item label="合同签订日期">{{ replaceTimeT(info.qdRq) || '--' }}</el-descriptions-item>

        </el-descriptions>
      </div>
    </div>
    <ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showPayBtn="showPayBtn" :text="text"></ToPayOrMember>
  </div>
</template>

<script>
import ToPayOrMember from "@/components/ToPayOrMember"
import { doEncrypt, doDecryptStr } from "@/utils/smCrypto.js"

export default {
  components: {
    ToPayOrMember
  },
  data() {
    return {
      showPayBtn: false,
      text: "",
      detailData: {},
      isFullscreen: false,
      info: {}
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.getTenderInfoDetail(id);
  },
  methods: {
    replaceTimeT(value) {

      if (value) {
        value = value.toString()
        if (value.indexOf('T') != -1) {
          value = value.replace('T', ' ')
        } else {
          var dateObj = new Date(value*1); 
          // 通过getFullYear()、getMonth()等方法获取年月日等信息
          var year = dateObj.getFullYear();
          var month = dateObj.getMonth() + 1; // getMonth()返回值范围从0到11，所以需要加1
          var day = dateObj.getDate();
          var hours = dateObj.getHours();
          var minutes = dateObj.getMinutes();
          var seconds = dateObj.getSeconds(); 
          // 构造日期字符串
          value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
      }
      return value
    },
    getTenderInfoDetail(id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false
      });
      this.$api.industry
        .getLandTransactionDetail({
          platform: 1,
          id: id
        })
        .then(res => {
          this.detailData = res.data;
          this.info = JSON.parse(res.data.contents).data;
        })
        .catch(msg => {
          if (msg.code == '10001111') {
            this.$refs.toPayOrMember.openDialog()
            this.text = msg?.msg
          } else {
            this.$message.error(msg?.msg);
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    // 网页全屏方法
    click() {
      if (this.isFullscreen) {
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".file-box").style.width = "1200px";
      } else {
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".file-box").style.width = "100vw";
      }
    },
    // 查看原文
    toOriginal(url) {
      this.$confirm(
        "您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        window.open(url, "_blank");
      });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
